import { useSnackbar } from "notistack";

export let useSnackbarRef = null;

export function SnackbarUtilsConfigurator() {
  useSnackbarRef = useSnackbar();

  return null;
}

export const errorHandlingMiddleware = (_) => (next) => async (action) => {
  const data = await next(action);

  if (data.error === undefined) {
    return data;
  }

  if (data.payload?.status === "FETCH_ERROR") {
    useSnackbarRef.enqueueSnackbar(
      "Could not communicate with server, please retry in a bit."
    );
  } else {
    const payloadErrors = data.payload?.data;
    if (payloadErrors !== undefined) {
      const errors = Object.entries(payloadErrors);
      for (let e of errors) {
        useSnackbarRef.enqueueSnackbar(`${e[0].toString()}: ${e[1]}`);
      }
    }
  }

  return data;
};
