import {
  useGetCandidatesQuery,
  useGetStagesQuery,
  useGetUserQuery,
} from "../api/Service";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import Loader from "../components/atoms/Loader";
import ArchiveCandidateTable from "../components/molecules/ArchiveCandidateTable";

function _Archive() {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">List of Archived Candidates</Typography>
        </Box>
        <Box pt={2} />
        <ArchiveCandidateTable />
      </CardContent>
    </Card>
  );
}

// Archive.queries = [useGetUserQuery, useGetCandidatesQuery, useGetStagesQuery];

export default function Archive() {
  const { isLoading: isUserLoading } = useGetUserQuery();
  const { isLoading: isCandidatesLoading } = useGetCandidatesQuery();
  const { isLoading: isStagesLoading } = useGetStagesQuery();

  if (isUserLoading || isCandidatesLoading || isStagesLoading) {
    return <Loader />;
  } else {
    return <_Archive />;
  }
}
