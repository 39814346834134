import { useUpdateCandidateMutation } from "../../api/Service";
import React, { useState } from "react";
import {
  Button,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import { CANDIDATE_DETAIL } from "../../AppRouter";
import { ModeEdit, MoreHoriz, Unarchive } from "@mui/icons-material";
import { StageSelector } from "../atoms/FilterSelector";
import { useSelector } from "react-redux";
import {
  archivedResetSearchFilter,
  archivedSetHiringManager,
  archivedSetInAts,
  archivedSetRole,
  archivedSetScheduledDate,
  archivedSetSearchFilter,
  archivedToggleStageFilter,
  archivedToggleStateFilter,
  selectArchivedFilters,
  selectArchivedHiringManager,
  selectArchivedInAts,
  selectArchivedRole,
  selectArchivedScheduledDate,
  selectArchivedSearchName,
} from "../../state/FilterSlice";
import {
  selectArchivedCandidateHiringManagers,
  selectArchivedCandidateRoles,
  selectArchivedCandidatesNormalized,
  selectFilteredArchivedCandidates,
} from "../../state/CandidateSlice";
import CandidateTable from "../organisms/CandidateTable";

function ArchiveTableColGroup() {
  return (
    <colgroup>
      {/* Name */}
      <col style={{ width: "14%" }} />
      {/* Hiring Manager */}
      <col style={{ width: "12%" }} />
      {/* In Ats */}
      <col style={{ width: "5%" }} />
      {/* Role */}
      <col style={{ width: "14%" }} />
      {/* Stage */}
      <col style={{ width: "12%" }} />
      {/* Notes */}
      <col style={{ width: "40%" }} />
      {/* Actions button */}
      <col style={{ width: "3%" }} />
    </colgroup>
  );
}

function ArchiveTableHeader() {
  return (
    <TableRow>
      <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Hiring Manager</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>In ATS?</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Role</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Stage</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Notes</TableCell>
      <TableCell padding="checkbox" />
    </TableRow>
  );
}

export function ArchivedCandidateRow(props) {
  const [updateCandidate] = useUpdateCandidateMutation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEditClick = () => {
    handleMenuClose();
    props.openEdit(props.candidate);
  };
  const handleUnarchiveClick = () => {
    handleMenuClose();
    updateCandidate({ pk: props.candidate.pk, is_archived: false });
  };

  const updateCandidateStageHandler = (stage) => {
    updateCandidate({ pk: props.candidate.pk, stage: stage });
  };

  return (
    <TableRow
      sx={{
        borderStyle: "solid",
        borderWidth: "0 0 0 20px",
        borderColor: props.candidate.stage.color,
      }}
    >
      <TableCell>
        <Link to={CANDIDATE_DETAIL.generate(props.candidate.pk)}>
          {props.candidate.name}
        </Link>
      </TableCell>
      <TableCell>{props.candidate.hiring_manager}</TableCell>
      <TableCell>{props.candidate.in_ats ? "Yes" : "No"}</TableCell>
      <TableCell>{props.candidate.role}</TableCell>
      <TableCell>
        <StageSelector
          value={props.candidate.stage.pk}
          onChange={updateCandidateStageHandler}
        />
      </TableCell>
      <TableCell>{props.candidate.notes}</TableCell>
      <TableCell>
        <Button sx={{ color: "black" }} onClick={handleMenuOpen}>
          <MoreHoriz />
        </Button>
        <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose}>
          <MenuItem onClick={handleEditClick}>
            <ListItemIcon>
              <ModeEdit />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={handleUnarchiveClick}>
            <ListItemIcon>
              <Unarchive />
            </ListItemIcon>
            Unarchive
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

export default function ArchiveCandidateTable() {
  const filters = useSelector(selectArchivedFilters);

  const allCandidates = useSelector(selectArchivedCandidatesNormalized);
  const filteredCandidates = useSelector(selectFilteredArchivedCandidates);

  const selectors = {
    selectSearchName: selectArchivedSearchName,
    selectInAts: selectArchivedInAts,
    selectRole: selectArchivedRole,
    selectHiringManager: selectArchivedHiringManager,
    selectCandidateRoles: selectArchivedCandidateRoles,
    selectCandidateHiringManagers: selectArchivedCandidateHiringManagers,
    selectFilters: selectArchivedFilters,
    selectScheduledDate: selectArchivedScheduledDate,
  };

  const dispatchers = {
    resetSearchFilter: archivedResetSearchFilter,
    setSearchFilter: archivedSetSearchFilter,
    setHiringManager: archivedSetHiringManager,
    setRole: archivedSetRole,
    setInAts: archivedSetInAts,
    toggleStateFilter: archivedToggleStateFilter,
    toggleStageFilter: archivedToggleStageFilter,
    setScheduledDate: archivedSetScheduledDate,
  };

  return (
    <CandidateTable
      dispatch={dispatchers}
      selectors={selectors}
      CandidateRow={ArchivedCandidateRow}
      allCandidates={allCandidates}
      filteredCandidates={filteredCandidates}
      filters={filters}
      TableColGroup={ArchiveTableColGroup}
      TableHeader={ArchiveTableHeader}
      colSpan={7}
    />
  );
}
