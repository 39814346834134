import { useSelector } from "react-redux";
import { selectStatusesList } from "../../state/StatusSlice";
import CheckboxMenu from "../atoms/CheckboxMenu";
import { selectStageByName, selectStagesList } from "../../state/StageSlice";

function SelectFilter(props) {
  const filters = props.values
    .map((s) => s.name)
    .reduce((acc, status) => {
      let item = {
        name: status,
        checked: status in props.filters,
        onClick: (e) => {
          props.onClick(e);
        },
      };
      // TODO: Cleanup.
      if (props.colorMap) {
        item.backgroundColor = props.colorMap[status].color;
      }
      acc.push(item);
      return acc;
    }, []);

  return (
    <CheckboxMenu title={props.title} color={props.color} items={filters} />
  );
}

export function StatusFilter(props) {
  const values = useSelector(selectStatusesList);

  return (
    <SelectFilter
      title="Select"
      color="secondary"
      values={values}
      filters={props.filters.status}
      onClick={props.onClick}
    />
  );
}

export function StageFilter(props) {
  const values = useSelector(selectStagesList);
  const stageByName = useSelector(selectStageByName);

  return (
    <SelectFilter
      title="Stage"
      color="tertiary"
      values={values}
      filters={props.filters.stage}
      colorMap={stageByName}
      onClick={props.onClick}
    />
  );
}
