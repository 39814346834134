import { useDispatch } from "react-redux";
import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { AdvancedSearch } from "../molecules/CandidateTable";
import { PlaylistPlay, PlaylistRemove } from "@mui/icons-material";
import { CandidateChips } from "./CandidateFilter";
import { CandidateModifier } from "./CandidateEdit";

export function CandidateTableBody(props) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event) => {
    props.setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const CandidateRow = props.CandidateRow;

  return (
    <React.Fragment>
      <TableBody>
        {props.candidates.length === 0 ? (
          <TableRow>
            <TableCell colSpan={8}>
              Any candidates will appear here :)
            </TableCell>
          </TableRow>
        ) : (
          props.candidates
            .slice(
              props.page * rowsPerPage,
              props.page * rowsPerPage + rowsPerPage
            )
            .map((candidate) => (
              <CandidateRow key={candidate.pk} candidate={candidate} />
            ))
        )}
        <TableRow>
          <TablePagination
            count={props.candidates.length}
            page={props.page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableBody>
    </React.Fragment>
  );
}

export default function CandidateTable(props) {
  const dispatch = useDispatch();

  const filters = props.filters;
  const allCandidates = props.allCandidates;
  const filteredCandidates = props.filteredCandidates;

  const numAllCandidates = allCandidates.length;
  const numFilteredCandidates = filteredCandidates.length;

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editCandidate, setEditCandidate] = useState({});
  const [page, setPage] = useState(0);

  const handleOpenEdit = (candidate) => {
    setEditCandidate(candidate);
    setEditOpen(true);
  };

  const setSearchResultsHandler = () => {
    setPage(0);
  };

  const _CandidateRow = props.CandidateRow;
  const CandidateRow = (props) => {
    return <_CandidateRow openEdit={handleOpenEdit} {...props} />;
  };

  const TableColGroup = props.TableColGroup;
  const TableHeader = props.TableHeader;

  const handleStatusFilterClick = (filter) => {
    setPage(0);
    dispatch(props.dispatch.toggleStateFilter(filter));
  };

  const handleStageFilterClick = (filter) => {
    setPage(0);
    dispatch(props.dispatch.toggleStageFilter(filter));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <AdvancedSearch
        open={advancedSearchOpen}
        onChange={setSearchResultsHandler}
        selectors={props.selectors}
        dispatch={props.dispatch}
        setPage={setPage}
        showScheduledDateRange={props.showScheduledDateRange}
      />
      <Paper sx={{ width: "100%", minWidth: 0 }}>
        <TableContainer>
          <Table sx={{ tableLayout: "auto" }}>
            <TableColGroup />
            <TableHead>
              <TableRow>
                <TableCell colSpan={props.colSpan}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => setAdvancedSearchOpen(!advancedSearchOpen)}
                      startIcon={
                        advancedSearchOpen ? (
                          <PlaylistRemove />
                        ) : (
                          <PlaylistPlay />
                        )
                      }
                    >
                      {advancedSearchOpen ? "Hide Controls" : "Show Controls"}
                    </Button>
                    <Box pr={1} />
                    <Typography variant="body1">
                      Showing{" "}
                      <Box fontWeight="fontWeightBold" component={"span"}>
                        {numFilteredCandidates}
                      </Box>{" "}
                      out of{" "}
                      <Box fontWeight="fontWeightBold" component={"span"}>
                        {numAllCandidates}
                      </Box>{" "}
                      active candidates
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              {
                // TODO: Better way of handling this.
                (Object.keys(filters.status).length !== 0 ||
                  Object.keys(filters.stage).length !== 0) && (
                  <TableRow>
                    <TableCell colSpan={props.colSpan}>
                      <CandidateChips
                        filters={filters}
                        onStatusClick={handleStatusFilterClick}
                        onStageClick={handleStageFilterClick}
                      />
                    </TableCell>
                  </TableRow>
                )
              }
              <TableHeader />
            </TableHead>
            <CandidateTableBody
              candidates={filteredCandidates}
              CandidateRow={CandidateRow}
              page={page}
              setPage={setPage}
            />
            <CandidateModifier
              open={editOpen}
              onClose={() => setEditOpen(false)}
              candidate={editCandidate}
            />
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
