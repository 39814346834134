import {
  Archive,
  HomeOutlined,
  List,
  Palette,
  Settings,
} from "@mui/icons-material";
import { Box, Drawer, IconButton, ListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/UserSlice";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {
  ARCHIVE,
  CANDIDATES_LIST,
  HOME,
  PREFERENCES,
  SETTINGS,
} from "../../AppRouter";

function PageItem(props) {
  const location = useLocation();

  return (
    <Box
      sx={{
        ...(props.item.route === location.pathname
          ? {
              backgroundColor: "primary.lighter",
              color: "primary.main",
            }
          : {
              color: "gray",
            }),
      }}
    >
      <Link
        to={`${props.item.route}`}
        onClick={props.handleClick}
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <ListItem
          button
          sx={{
            userSelect: "none",
            msUserSelect: "none",
            paddingLeft: 4,
            alignItems: "end",
          }}
        >
          {props.item.icon}
          <Typography
            variant="subtitle2"
            sx={{
              paddingLeft: 1,
            }}
          >
            {props.item.name}
          </Typography>
        </ListItem>
      </Link>
    </Box>
  );
}

function SidebarItems(props) {
  const user = useSelector(selectUserInfo);

  const menuItems = [
    {
      title: "General",
      items: [
        {
          name: "Home",
          route: HOME.generate(),
          icon: <HomeOutlined />,
        },
        {
          name: "Candidates",
          route: CANDIDATES_LIST.generate(),
          icon: <List />,
        },
        {
          name: "Archive",
          route: ARCHIVE.generate(),
          icon: <Archive />,
        },
      ],
    },
    {
      title: "Management",
      items: [
        {
          name: "Preferences",
          route: PREFERENCES.generate(),
          icon: <Palette />,
          // isAuthenticated: true,
        },
        {
          name: "Settings",
          route: SETTINGS.generate(),
          icon: <Settings />,
          // isAuthenticated: true,
        },
      ],
    },
  ];

  const handleClick = () => {
    props.onClick();
  };

  return (
    <React.Fragment>
      {menuItems.map((category, i) => {
        if (
          !user.isAuthenticated &&
          !category.items.some((e) => !e.isAuthenticated)
        ) {
          return null;
        }
        return (
          <Box
            key={i}
            pt={3}
            sx={{
              width: "250px",
            }}
          >
            <ListItem>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {category.title}
              </Typography>
            </ListItem>
            {category.items.map((item, i) =>
              !item.isAuthenticated || user.isAuthenticated ? (
                <PageItem key={i} item={item} handleClick={handleClick} />
              ) : null
            )}
          </Box>
        );
      })}
    </React.Fragment>
  );
}

export default function Sidebar(props) {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        sx={{ mr: 2 }}
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <SidebarItems onClick={() => setOpen(false)} />
      </Drawer>
    </Box>
  );
}
