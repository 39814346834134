import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

export default function CandidateInputs(props) {
  const stateKeysOrDefault = (defaultValue) =>
    props.fields.reduce((map, field) => {
      map[field.key] =
        field.default === undefined ? defaultValue : field.default;
      return map;
    }, {});

  const stateKeys = (value) =>
    props.fields.reduce((map, field) => {
      map[field.key] = value;
      return map;
    }, {});

  const [state, _setState] = useState(stateKeysOrDefault(""));
  const [errors, _setError] = useState(stateKeys(false));

  const setStateWrapper = (setter, name, value) => {
    setter((prevState) => ({ ...prevState, [name]: value }));
  };

  const setState = (name, value) => {
    setStateWrapper(_setState, name, value);
  };

  const setError = (name, value) => {
    setStateWrapper(_setError, name, value);
  };

  const onChangeWrapper = (name) => {
    return (value) => {
      setState(name, value);
    };
  };

  const addCandidateHandler = () => {
    let hasError = false;
    for (const field of props.fields) {
      setError(field.key, false);

      const setErrorTrue = () => {
        hasError = true;
        setError(field.key, true);
      };

      if (field.isError !== undefined) {
        if (field.isError(state[field.key])) {
          setErrorTrue();
        }
      } else if (!field.nullable && state[field.key] === "") {
        setErrorTrue();
      }
    }

    if (!hasError) {
      props.onConfirm({ pk: props.pk, ...state });
    }
  };

  const onCancel = () => {
    props.onCancel();
  };

  const onAdd = () => {
    addCandidateHandler();
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Candidate Information</Typography>

        <Divider
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        />

        <Grid container justifyContent="space-between" spacing={6}>
          {props.fields.map((field, i, arr) => {
            const Component = field.component;
            return (
              <Grid item xs={i === arr.length - 1 ? 12 : 6} key={field.key}>
                <Component
                  id={`candidate-input-${field.key}`}
                  name={field.name}
                  value={state[field.key]}
                  onChange={onChangeWrapper(field.key)}
                  error={errors[field.key]}
                />
              </Grid>
            );
          })}
        </Grid>

        <Divider
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Box sx={{ paddingRight: 2 }} />
          <Button
            id={props.confirmButtonId}
            variant="contained"
            onClick={() => onAdd()}
          >
            {props.confirmText}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
