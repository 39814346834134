import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectCandidatesNormalizedMap } from "../state/CandidateSlice";
import React, { useState } from "react";
import {
  useGetCandidateEventsQuery,
  useGetCandidatesQuery,
  useGetStagesQuery,
  useGetUserQuery,
} from "../api/Service";
import Loader from "../components/atoms/Loader";
import { LOCALE_STRING_OPTIONS } from "../constants";
import { CandidateModifier } from "../components/organisms/CandidateEdit";
import { OpenInNew } from "@mui/icons-material";

function CandidateInfoList(props) {
  const zip = (rows) => rows[0].map((_, c) => rows.map((row) => row[c]));

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {zip(props.value).map((column, i) => (
        <Box key={i} sx={{ ...(i > 0 && { paddingLeft: 2 }) }}>
          {column.map((cell, j) => (
            <Typography
              key={j}
              sx={{ ...(i === 1 && { fontWeight: "fontWeightBold" }) }}
            >
              {cell}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
}

function CandidateInfo(props) {
  const [editOpen, setEditOpen] = useState(false);

  let candidateInfo = [
    ["Hiring Manager", props.candidate.hiring_manager],
    ["In ATS?", props.candidate.in_ats ? "Yes" : "No"],
    ["Role", props.candidate.role],
    ["Stage", props.candidate.stage.name],
    ["Status", props.candidate.status],
  ];

  const ats_link = props.candidate.ats_link.trim();
  if (ats_link !== "") {
    const protocol_ats_link = ats_link.startsWith("http")
      ? ats_link
      : "https://" + ats_link;
    candidateInfo.push([
      "ATS Link",
      <span>
        <a href={protocol_ats_link} target="_blank" rel="noreferrer">
          {ats_link}
          <OpenInNew sx={{ fontSize: 15 }} />
        </a>
      </span>,
    ]);
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{props.candidate.name}</Typography>
        <Button variant="outlined" onClick={() => setEditOpen(true)}>
          Edit
        </Button>
      </Box>
      <Box pt={1} />
      <CandidateInfoList value={candidateInfo} />
      <CandidateModifier
        open={editOpen}
        onClose={() => setEditOpen(false)}
        candidate={props.candidate}
      />
    </React.Fragment>
  );
}

function CandidateNote(props) {
  return (
    <React.Fragment>
      <Typography variant="subtitle2">
        {new Date(props.date).toLocaleDateString(
          "en-US",
          LOCALE_STRING_OPTIONS
        )}
      </Typography>
      <Typography variant="body1">{props.note}</Typography>
    </React.Fragment>
  );
}

function CandidateNotes(props) {
  const note_events = props.candidate.events.filter(
    (e) => e.column === "notes"
  );

  const transformEmpty = (n) => (n.trim() === "" ? "<Empty Notes>" : n);

  let notes;

  if (note_events.length > 0) {
    const last_event = note_events[note_events.length - 1];
    notes = note_events
      .map((e) => ({
        date: e.date,
        value: e.to_value,
      }))
      .concat([
        {
          date: props.candidate.created,
          value: last_event.from_value,
        },
      ]);
  } else {
    notes = [{ date: props.candidate.created, value: props.candidate.notes }];
  }

  return (
    <React.Fragment>
      <Typography variant="h5">Notes</Typography>
      <Box pt={1} />
      {notes.map((n, i) => (
        <Box sx={{ ...(i > 0 && { paddingTop: 2 }) }} key={i}>
          <CandidateNote date={n.date} note={transformEmpty(n.value)} />
        </Box>
      ))}
    </React.Fragment>
  );
}

function CandidateActivityLog(props) {
  const events = props.candidate.events.filter((e) => e.column !== "notes");

  const transformDate = (d) => {
    if (d !== null) {
      return new Date(d).toLocaleDateString("en-US", LOCALE_STRING_OPTIONS);
    } else {
      return "<None>";
    }
  };

  const transformEvent = (event) => {
    const e = { ...event };
    if (e.column === "scheduled_date") {
      e.from_value = transformDate(e.from_value);
      e.to_value = transformDate(e.to_value);
    }
    return e;
  };

  return (
    <React.Fragment>
      <Typography variant="h6">Event History</Typography>
      <Box pt={1} />
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Column</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event, i) => {
                const e = transformEvent(event);
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {new Date(e.date).toLocaleDateString(
                        "en-US",
                        LOCALE_STRING_OPTIONS
                      )}
                    </TableCell>
                    <TableCell>{e.column}</TableCell>
                    <TableCell>{e.from_value}</TableCell>
                    <TableCell>{e.to_value}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

function _CandidateDetail(props) {
  const candidate = props.candidate;

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Card>
          <CardContent>
            <CandidateInfo candidate={candidate} />
          </CardContent>
        </Card>
        <Box pt={2} />
        <Card>
          <CardContent>
            <CandidateNotes candidate={candidate} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={7}>
        <Card>
          <CardContent>
            <CandidateActivityLog candidate={candidate} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

// CandidateDetail.queries = [
//   useGetUserQuery,
//   useGetCandidatesQuery,
//   useGetStagesQuery,
// ];

export default function CandidateDetail() {
  const params = useParams();
  const { isLoading, data: events } = useGetCandidateEventsQuery(params.pk);
  const candidates = useSelector(selectCandidatesNormalizedMap);
  const candidate = candidates[params.pk];

  const { isLoading: isUserLoading } = useGetUserQuery();
  const { isLoading: isCandidatesLoading } = useGetCandidatesQuery();
  const { isLoading: isStagesLoading } = useGetStagesQuery();

  if (isLoading || isUserLoading || isCandidatesLoading || isStagesLoading) {
    return <Loader />;
  } else {
    candidate["events"] = events;
    return <_CandidateDetail candidate={candidate} />;
  }
}
