import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Collapse,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import TextSearch from "../atoms/TextSearch";
import { StageFilter, StatusFilter } from "./SelectFilters";
import React from "react";
import { DateTimePicker } from "@mui/lab";

export function AdvancedSearch(props) {
  const dispatch = useDispatch();
  const ELEMENT_PADDING = 2;

  const name = useSelector(props.selectors.selectSearchName);
  const inAts = useSelector(props.selectors.selectInAts);
  const role = useSelector(props.selectors.selectRole);
  const hiringManager = useSelector(props.selectors.selectHiringManager);
  const candidateRoles = useSelector(props.selectors.selectCandidateRoles);
  const candidateHiringManagers = useSelector(
    props.selectors.selectCandidateHiringManagers
  );
  const scheduledDate = useSelector(props.selectors.selectScheduledDate);
  const filters = useSelector(props.selectors.selectFilters);

  const onStateClick = (e) => {
    dispatch(props.dispatch.toggleStateFilter(e));
    props.setPage(0);
  };

  const onStageClick = (e) => {
    dispatch(props.dispatch.toggleStageFilter(e));
    props.setPage(0);
  };

  const onScheduledDateSetFrom = (e) => {
    if (e !== null) {
      dispatch(props.dispatch.setScheduledDate({ from: e["$d"].toJSON() }));
    }
  };

  const onScheduledDateSetTo = (e) => {
    if (e !== null) {
      dispatch(props.dispatch.setScheduledDate({ to: e["$d"].toJSON() }));
    }
  };

  // TODO: Have the stage / status filters also set page to 0.

  return (
    <Collapse in={props.open} orientation="horizontal">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingY: 2,
          paddingLeft: 1,
          paddingRight: 2,
          minWidth: "200px",
        }}
      >
        <TextSearch
          label="Candidate Name"
          value={name ?? ""}
          onChange={(value) => {
            dispatch(props.dispatch.setSearchFilter(value));
            props.onChange();
          }}
        />
        <Box pt={ELEMENT_PADDING} />
        <TextField
          label="Hiring Manager"
          value={hiringManager}
          size="small"
          select
          onChange={(e) => {
            dispatch(props.dispatch.setHiringManager(e.target.value));
            props.onChange();
          }}
        >
          <MenuItem value={""}>Any</MenuItem>
          {candidateHiringManagers.map((h, i) => (
            <MenuItem key={i} value={h}>
              {h}
            </MenuItem>
          ))}
        </TextField>
        <Box pt={ELEMENT_PADDING} />
        <TextField
          label="Role"
          value={role}
          size="small"
          select
          onChange={(e) => {
            dispatch(props.dispatch.setRole(e.target.value));
            props.onChange();
          }}
        >
          <MenuItem value={""}>Any</MenuItem>
          {candidateRoles.map((r, i) => (
            <MenuItem key={i} value={r}>
              {r}
            </MenuItem>
          ))}
        </TextField>
        <Box pt={ELEMENT_PADDING} />
        <TextField
          label="In Ats?"
          value={inAts}
          size="small"
          select
          onChange={(e) => {
            dispatch(props.dispatch.setInAts(e.target.value));
            props.onChange();
          }}
        >
          <MenuItem value={""}>Either</MenuItem>
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
        </TextField>
        <Box pt={ELEMENT_PADDING} />
        <StatusFilter filters={filters} onClick={onStateClick} />
        <Box pt={ELEMENT_PADDING} />
        <StageFilter filters={filters} onClick={onStageClick} />
        <Box pt={ELEMENT_PADDING} />
        {props.showScheduledDateRange && (
          <React.Fragment>
            <Typography variant="subtitle2">Scheduled Date</Typography>
            <Box pt={0.5} />
            <DateTimePicker
              label={"From"}
              value={scheduledDate.from}
              onChange={onScheduledDateSetFrom}
              renderInput={(params) => <TextField {...params} />}
              clearable
            />
            <Box pt={1} />
            <DateTimePicker
              label={"To"}
              value={scheduledDate.to}
              onChange={onScheduledDateSetTo}
              renderInput={(params) => <TextField {...params} />}
              clearable
            />
            <Box pt={ELEMENT_PADDING} />
          </React.Fragment>
        )}
        <Button onClick={() => dispatch(props.dispatch.resetSearchFilter())}>
          Clear Search
        </Button>
      </Box>
    </Collapse>
  );
}
