export const FONT_FAMILY = `"Public Sans"`;
export const LOCALE_STRING_OPTIONS = {
  year: "numeric",
  month: "long",
  weekday: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};
export const LOCALE_SHORT_STRING_OPTIONS = {
  month: "long",
  weekday: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};
