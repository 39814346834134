import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  0: { name: "Scheduling", pk: 0 },
  1: { name: "Scheduled", pk: 1 },
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {},
});

export const selectStatuses = (state) => state.status;

export const selectStatusesList = createSelector([selectStatuses], (c) => {
  return Object.keys(c).map((key) => c[key]);
});

export const selectStatusByName = createSelector([selectStatuses], (c) => {
  return Object.values(c).reduce((acc, v) => {
    acc[v.name] = v;
    return acc;
  }, {});
});

export const { addStatus } = statusSlice.actions;
export default statusSlice.reducer;
