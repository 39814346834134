import { createApi } from "@reduxjs/toolkit/query/react";
import tokenRefreshMiddleware from "../middleware/tokenRefreshMiddleware";
import { resetCredentials } from "../state/UserSlice";

export const api = createApi({
  reducerPath: "api",
  baseQuery: tokenRefreshMiddleware,
  tagTypes: ["User", "Candidate", "Stage"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/api/token/",
        method: "POST",
        body: credentials,
      }),
    }),
    logout: builder.mutation({
      query: () => ({}),
      async onQueryStarted(id, { dispatch }) {
        dispatch(resetCredentials());
      },
    }),
    getUser: builder.query({
      query: (id) => "/user/",
      transformResponse: (response) => response[0],
      providesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: (patch) => ({
        url: "/user/",
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => ["User"],
    }),
    getCandidate: builder.query({
      query: (id) => `/candidate/${id}/`,
      providesTags: (result, error, id) => [{ type: "Candidate", id }],
    }),
    getCandidateEvents: builder.query({
      query: (id) => `/candidate/${id}/events/`,
      providesTags: (result, error, id) => [{ type: "Candidate", id }],
      transformResponse: (response) => response,
    }),
    getCandidates: builder.query({
      query: () => "/candidate/",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Candidate", id })),
              { type: "Candidate", id: "LIST" },
            ]
          : [{ type: "Candidate", id: "LIST" }],
      transformResponse: (response) => response,
    }),
    getStages: builder.query({
      query: () => "/stage/",
      providesTags: ["Stage"],
      transformResponse: (response) => response,
    }),
    addCandidate: builder.mutation({
      query: ({ ...candidate }) => {
        if (candidate.is_archived === undefined) {
          candidate.is_archived = false;
        }
        return {
          url: "/candidate/",
          method: "POST",
          body: candidate,
        };
      },
      invalidatesTags: [{ type: "Candidate", id: "LIST" }],
    }),
    updateCandidate: builder.mutation({
      query: ({ pk, ...patch }) => ({
        url: `/candidate/${pk}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Candidate", id }],
    }),
    addStage: builder.mutation({
      query: ({ ...stage }) => ({
        url: "/stage/",
        method: "POST",
        body: stage,
      }),
      invalidatesTags: ["Stage"],
    }),
    updateStage: builder.mutation({
      query: ({ pk, ...stage }) => ({
        url: `/stage/${pk}/`,
        method: "PATCH",
        body: stage,
      }),
      invalidatesTags: ["Stage"],
    }),
    deleteStage: builder.mutation({
      query: ({ pk, ...stage }) => ({
        url: `/stage/${pk}/`,
        method: "DELETE",
        body: stage,
      }),
      invalidatesTags: ["Stage"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetCandidatesQuery,
  useGetCandidateEventsQuery,
  useGetStagesQuery,
  useAddCandidateMutation,
  useUpdateUserMutation,
  useUpdateCandidateMutation,
  useLoginMutation,
  useAddStageMutation,
  useUpdateStageMutation,
  useDeleteStageMutation,
  useLogoutMutation,
} = api;
