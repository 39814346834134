import { createSelector, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/Service";
import Cookies from "js-cookie";

const initialState = {
  token: Cookies.get("token"),
  refreshToken: Cookies.get("refreshToken"),
  name: null,
  email: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.token = payload.access;
      state.refreshToken = payload.refresh;
      Cookies.set("token", state.token);
      Cookies.set("refreshToken", state.refreshToken);
    },
    resetCredentials: (state) => {
      state.token = null;
      state.refreshToken = null;
      Cookies.set("token", null);
      Cookies.set("refreshToken", null);
    },
    setUserState: (state) => {
      // TODO
    },
  },
});

export const selectUser = (state) => state.user;

export const selectUserResult = api.endpoints.getUser.select();

export const selectUserInfo = createSelector(
  selectUserResult,
  (result) => result?.data ?? {}
);

export const { setUserState, setCredentials, resetCredentials } =
  userSlice.actions;
export default userSlice.reducer;
