import { Box } from "@mui/material";
import { StatusChips, StageChips } from "../molecules/FilterChips";

export function CandidateChips(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: -1,
        "> *": {
          marginTop: 1,
        },
      }}
    >
      <StatusChips filters={props.filters} onClick={props.onStatusClick} />
      <StageChips filters={props.filters} onClick={props.onStageClick} />
    </Box>
  );
}
