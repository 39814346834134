import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { FileDownload, OpenInNew } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import { selectFilteredCandidates } from "../../state/CandidateSlice";

// Function to download data to a file, taken from https://stackoverflow.com/a/30832210
function download(data, filename, type) {
  let file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    let a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

const columns = [
  { name: "Name", id: "name" },
  { name: "Hiring Manager", id: "hiring_manager" },
  { name: "In Ats?", id: "in_ats" },
  { name: "Role", id: "role" },
  { name: "Stage", id: "stage", object: true },
  { name: "Status", id: "status" },
  { name: "Notes", id: "notes" },
  { name: "Is Archived?", id: "is_archived" },
];

function candidateToCsv(candidate) {
  return columns
    .map((c) => (c.object ? candidate[c.id].name : candidate[c.id]))
    .join(",");
}

export default function CandidateExporter() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO: Might be better to only pull candidates when the function is called,
  // for performance reasons?
  const candidates = useSelector(selectFilteredCandidates);

  const exportExcel = () => {
    handleClose();

    const data =
      columns.map((c) => c.name).join(",") +
      "\n" +
      candidates.map(candidateToCsv).join("\n");
    download(data, "candidates.csv", "csv");
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClick} endIcon={<OpenInNew />}>
        Export
      </Button>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem onClick={exportExcel}>
          <ListItemIcon>
            <FileDownload />
          </ListItemIcon>
          <ListItemText>CSV</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
