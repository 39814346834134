import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import React from "react";

export default function EditableText(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [value, setValue] = useState(props.value);

  const handleClick = () => {
    setIsEditing(true);
    setEditShow(false);
  };

  const handleBlur = () => {
    setIsEditing(false);

    if (value.trim() === "") {
      setValue(props.value);
      return;
    }

    if (value !== props.value) {
      props.onChange(value);
    }
  };

  if (isEditing) {
    return (
      <TextField
        size="small"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Escape") {
            e.stopPropagation();
            handleBlur();
          }
        }}
      />
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          width: "fit-content",
          flexDirection: "row",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onMouseEnter={() => setEditShow(true)}
        onMouseLeave={() => setEditShow(false)}
        onClick={handleClick}
      >
        <Typography variant="body2">{props.value}</Typography>
        {editShow && (
          <React.Fragment>
            <Box pl={1} />
            <Edit color="primary" fontSize="small" />
          </React.Fragment>
        )}
      </Box>
    );
  }
}
