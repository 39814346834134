import { createSelector, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/Service";

const initialState = {
  // 1: { name: "Recruiter Screen", color: "#673736", id: 1 },
  // 2: { name: "Hacker Rank", color: "#9FB68A", id: 2 },
  // 3: { name: "Technical Screen", color: "#3A405A", id: 3 },
  // 4: { name: "Onsite", color: "#99B2DD", id: 4 },
  // 5: { name: "Hiring Manager Review", color: "#E9AFA3", id: 5 },
  // 6: { name: "Coffee Chat", color: "#541388", id: 6 },
};

const stageSlice = createSlice({
  name: "stage",
  initialState,
  reducers: {
    addStage: (state, action) => {
      if (action.payload.id === undefined) {
        action.payload.id = Math.floor(Math.random() * 2 ** 16);
      }
      if (action.payload.color === undefined) {
        action.payload.color =
          "#" + Math.floor(Math.random() * 16777215).toString(16);
      }
      state[action.payload.id] = action.payload;
    },
    setStageColor: (state, action) => {
      state[action.payload.id].color = action.payload.color;
    },
    setStageName: (state, action) => {
      state[action.payload.id].name = action.payload.name;
    },
    deleteStage: (state, action) => {
      delete state[action.payload];
    },
  },
});

export const selectStagesResult = api.endpoints.getStages.select();

export const selectAllStages = createSelector(selectStagesResult, (result) =>
  (result?.data ?? []).map((s) => {
    const stage = { ...s };
    stage.color = "#" + s.color;
    return stage;
  })
);

export const selectStages = createSelector([selectAllStages], (stages) =>
  stages.reduce((acc, c) => {
    acc[c.pk] = c;
    return acc;
  }, {})
);

export const selectStagesList = createSelector([selectStages], (c) => {
  return Object.keys(c).map((key) => c[key]);
});

export const selectStageByName = createSelector([selectStages], (c) => {
  return Object.values(c).reduce((acc, v) => {
    acc[v.name] = v;
    return acc;
  }, {});
});

export const { addStage, setStageColor, setStageName, deleteStage } =
  stageSlice.actions;
export default stageSlice.reducer;
