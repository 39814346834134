import { useUpdateCandidateMutation } from "../../api/Service";
import React, { useState } from "react";
import {
  Box,
  Button,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { CANDIDATE_DETAIL } from "../../AppRouter";
import { StageSelector, StatusSelector } from "../atoms/FilterSelector";
import { LOCALE_SHORT_STRING_OPTIONS } from "../../constants";
import { Archive, ModeEdit, MoreHoriz } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  resetSearchFilter,
  selectFilters,
  selectHiringManager,
  selectInAts,
  selectRole,
  selectScheduledDate,
  selectSearchName,
  setHiringManager,
  setInAts,
  setRole,
  setScheduledDate,
  setSearchFilter,
  toggleStageFilter,
  toggleStateFilter,
} from "../../state/FilterSlice";
import {
  selectCandidateHiringManagers,
  selectCandidateRoles,
  selectFilteredCandidates,
  selectUnarchivedCandidates,
} from "../../state/CandidateSlice";
import CandidateTable from "../organisms/CandidateTable";

function ActiveTableColGroup() {
  return (
    <colgroup>
      {/* Name */}
      <col style={{ width: "14%" }} />
      {/* Hiring Manager */}
      <col style={{ width: "12%" }} />
      {/* In Ats */}
      <col style={{ width: "5%" }} />
      {/* Role */}
      <col style={{ width: "12%" }} />
      {/* Status */}
      <col style={{ width: "14%" }} />
      {/* Stage */}
      <col style={{ width: "10%" }} />
      {/* Notes */}
      <col style={{ width: "30%" }} />
      {/* Actions button */}
      <col style={{ width: "3%" }} />
    </colgroup>
  );
}

function ActiveTableHeader() {
  return (
    <TableRow>
      <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Hiring Manager</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>In ATS?</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Role</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Stage</TableCell>
      <TableCell sx={{ fontWeight: "bold" }}>Notes</TableCell>
      <TableCell padding="checkbox" />
    </TableRow>
  );
}

export function ActiveCandidateRow(props) {
  const [updateCandidate] = useUpdateCandidateMutation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEditClick = () => {
    handleMenuClose();
    props.openEdit(props.candidate);
  };
  const handleArchiveClick = () => {
    handleMenuClose();
    updateCandidate({ pk: props.candidate.pk, is_archived: true });
  };

  const updateCandidateStatusHandler = (status) => {
    updateCandidate({ pk: props.candidate.pk, status: status });
  };

  const updateCandidateStageHandler = (stage) => {
    updateCandidate({ pk: props.candidate.pk, stage: stage });
  };

  return (
    <TableRow
      sx={{
        borderStyle: "solid",
        borderWidth: "0 0 0 20px",
        borderColor: props.candidate.stage.color,
      }}
    >
      <TableCell>
        <Link to={CANDIDATE_DETAIL.generate(props.candidate.pk)}>
          {props.candidate.name}
        </Link>
      </TableCell>
      <TableCell>{props.candidate.hiring_manager}</TableCell>
      <TableCell>{props.candidate.in_ats ? "Yes" : "No"}</TableCell>
      <TableCell>{props.candidate.role}</TableCell>
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <StatusSelector
            value={props.candidate.status}
            onChange={updateCandidateStatusHandler}
          />
          {props.candidate.status === "Scheduled" &&
            props.candidate.scheduled_date !== null && (
              <Typography>
                at{" "}
                {new Date(props.candidate.scheduled_date).toLocaleDateString(
                  "en-US",
                  LOCALE_SHORT_STRING_OPTIONS
                )}
              </Typography>
            )}
        </Box>
      </TableCell>
      <TableCell>
        <StageSelector
          value={props.candidate.stage.pk}
          onChange={updateCandidateStageHandler}
        />
      </TableCell>
      <TableCell>{props.candidate.notes}</TableCell>
      <TableCell>
        <Button sx={{ color: "black" }} onClick={handleMenuOpen}>
          <MoreHoriz />
        </Button>
        <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose}>
          <MenuItem onClick={handleEditClick}>
            <ListItemIcon>
              <ModeEdit />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={handleArchiveClick}>
            <ListItemIcon>
              <Archive />
            </ListItemIcon>
            Archive
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

export default function ActiveCandidateTable() {
  const filters = useSelector(selectFilters);

  const allCandidates = useSelector(selectUnarchivedCandidates);
  const filteredCandidates = useSelector(selectFilteredCandidates);

  const selectors = {
    selectSearchName: selectSearchName,
    selectInAts: selectInAts,
    selectRole: selectRole,
    selectHiringManager: selectHiringManager,
    selectCandidateRoles: selectCandidateRoles,
    selectCandidateHiringManagers: selectCandidateHiringManagers,
    selectFilters: selectFilters,
    selectScheduledDate: selectScheduledDate,
  };

  const dispatchers = {
    resetSearchFilter: resetSearchFilter,
    setSearchFilter: setSearchFilter,
    setHiringManager: setHiringManager,
    setRole: setRole,
    setInAts: setInAts,
    toggleStateFilter: toggleStateFilter,
    toggleStageFilter: toggleStageFilter,
    setScheduledDate: setScheduledDate,
  };

  return (
    <CandidateTable
      dispatch={dispatchers}
      selectors={selectors}
      CandidateRow={ActiveCandidateRow}
      allCandidates={allCandidates}
      filteredCandidates={filteredCandidates}
      filters={filters}
      TableColGroup={ActiveTableColGroup}
      TableHeader={ActiveTableHeader}
      colSpan={8}
      showScheduledDateRange
    />
  );
}
