import Header from "./components/organisms/Header";
import { Button, Container } from "@mui/material";
import AppRouter, { LinkBehavior } from "./AppRouter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FONT_FAMILY } from "./constants";
import { green } from "@mui/material/colors";
import { selectUser } from "./state/UserSlice";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import React from "react";
import { SnackbarUtilsConfigurator } from "./middleware/errorHandling";

const globalTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 13,
  },
  palette: {
    primary: {
      main: "#1987d2",
      dark: "#1565c0",
      contrastText: "#fff",
      light: "#42a5f5",
      lighter: "#e3f2fd",
    },
    tertiary: {
      main: green["500"],
      light: green["300"],
      dark: green["700"],
      contrastText: "#fff",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

const isNull = (input) =>
  input === null ||
  input === undefined ||
  input === "null" ||
  input === "undefined";

function App() {
  const user = useSelector(selectUser);
  const notistackRef = React.createRef();

  const onClickNotificationDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={globalTheme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          variant="error"
          action={(key) => (
            <Button
              onClick={onClickNotificationDismiss(key)}
              sx={{ color: "primary.lighter" }}
            >
              Dismiss
            </Button>
          )}
          ref={notistackRef}
        >
          <SnackbarUtilsConfigurator />
          {isNull(user.token) ? (
            <Login />
          ) : (
            <React.Fragment>
              <Header />
              <Container
                sx={{
                  backgroundColor: "#eaeef3",
                  paddingTop: 2,
                  height: "100%",
                  flexGrow: 1,
                }}
                maxWidth={false}
              >
                <AppRouter />
              </Container>
            </React.Fragment>
          )}
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
