import { resetCredentials, setCredentials } from "../state/UserSlice";
import jwtDecode from "jwt-decode";
import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

const mutex = new Mutex();

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://api.retrostock.io";

const isNull = (input) =>
  input === null ||
  input === undefined ||
  input === "null" ||
  input === "undefined";

const isExpired = (token) => {
  const { exp } = jwtDecode(token);
  const currentTime = new Date().getTime() / 1000;

  // To try and make sure it doesn't expire during the request flight.
  return currentTime + 5 > exp;
};

const isValid = (token) => !isNull(token) && !isExpired(token);

const tokenRefreshMiddleware = async (args, api, extraOptions) => {
  // TODO: Find some way of not hardcoding these.
  // The only endpoint this should be true for is the logout mutation.
  if (Object.keys(args).length === 0) {
    return { data: null };
  }
  if (args.url === "/api/token/") {
    return fetchBaseQuery({
      baseUrl: BASE_URL,
    })(args, api, extraOptions);
  }

  const release = await mutex.acquire();
  let { token, refreshToken } = api.getState().user;
  let result;

  try {
    if (!isValid(token) && isValid(refreshToken)) {
      const formBody = `${encodeURIComponent("refresh")}=${encodeURIComponent(
        refreshToken
      )}`;

      result = await retry(
        fetchBaseQuery({
          baseUrl: `${BASE_URL}/api/token/refresh/`,
          method: "POST",
          prepareHeaders: (headers) => {
            headers.set(
              "Content-Type",
              "application/x-www-form-urlencoded;charset=UTF-8"
            );
            return headers;
          },
        }),
        { maxRetries: 3 }
      )({ method: "POST", body: formBody }, api, {});
      if (result.error !== undefined) {
        return { error: "Could not refresh token" };
      }
    }
  } finally {
    if (!isValid(token)) {
      if (result === undefined || result.error !== undefined) {
        api.dispatch(resetCredentials());
      } else {
        token = result.data.access;
        api.dispatch(setCredentials({ access: token, refresh: refreshToken }));
      }
    }
    release();
  }

  return retry(
    fetchBaseQuery({
      baseUrl: BASE_URL,
      prepareHeaders: (headers, { getState }) => {
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      },
    }),
    { maxRetries: 3 }
  )(args, api, extraOptions);
};

export default tokenRefreshMiddleware;
