import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectActiveCandidates,
  selectActiveCandidatesNormalized,
  selectArchivedCandidates,
} from "../state/CandidateSlice";
import Chart from "react-apexcharts";
import { selectStageByName } from "../state/StageSlice";
import { FONT_FAMILY } from "../constants";
import { CANDIDATE_DETAIL } from "../AppRouter";
import {
  useGetCandidatesQuery,
  useGetUserQuery,
  useGetStagesQuery,
  useUpdateCandidateMutation,
  useUpdateUserMutation,
} from "../api/Service";
import Loader from "../components/atoms/Loader";
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import "../styling/fullcalendar.css";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { selectUserInfo } from "../state/UserSlice";

function CandidatesChart() {
  const candidates = useSelector(selectActiveCandidatesNormalized);
  const stages = useSelector(selectStageByName);

  // TODO: Clean this up
  let stageColors = [];

  let stageCount = Object.entries(stages).reduce((acc, stage) => {
    acc[stage[0]] = 0;
    stageColors.push(stage[1].color);
    return acc;
  }, {});
  // TODO: Don't hardcode keys, for when we decide these can be changed.
  let statusCount = { Scheduled: 0, Scheduling: 0 };

  for (const candidate of candidates) {
    stageCount[candidate.stage.name] += 1;
    statusCount[candidate.status] += 1;
  }

  const stageEntries = Object.entries(stageCount);
  const stageTitles = stageEntries.map((a) => a[0]);
  const stageSeries = stageEntries.map((a) => a[1]);

  const statusEntries = Object.entries(statusCount);
  const statusTitles = statusEntries.map((a) => a[0]);
  const statusSeries = statusEntries.map((a) => a[1]);

  const stageOptions = {
    colors: stageColors,
    chart: {
      id: "candidates-stage-pie",
      fontFamily: FONT_FAMILY,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "right",
    },
    title: {
      text: "Stage",
      align: "center",
    },
    labels: stageTitles,
  };

  const statusOptions = {
    chart: {
      id: "candidates-status-pie",
      fontFamily: FONT_FAMILY,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "right",
    },
    title: {
      text: "Status",
      align: "center",
    },
    labels: statusTitles,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ width: "50%" }}>
        <Chart
          options={stageOptions}
          series={stageSeries}
          type="donut"
          height="200px"
        />
      </Box>
      <Box sx={{ width: "50%" }}>
        <Chart
          options={statusOptions}
          series={statusSeries}
          type="donut"
          height="200px"
        />
      </Box>
    </Box>
  );
}

function SchedulingList() {
  const candidates = useSelector(selectActiveCandidatesNormalized).filter(
    (c) => c.status === "Scheduling"
  );

  let candidatesList;

  if (candidates.length === 0) {
    candidatesList = (
      <Typography variant="body1">
        There are no candidates currently waiting to be scheduled! 🎉
      </Typography>
    );
  } else {
    candidatesList = (
      <Paper sx={{ overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "500px" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Stage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates.map((c, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Link to={CANDIDATE_DETAIL.generate(c.pk)}>{c.name}</Link>
                  </TableCell>
                  <TableCell>{c.stage.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Box>
      <Typography variant="h5">Candidates in Scheduling Status</Typography>
      <Box pt={1}>{candidatesList}</Box>
    </Box>
  );
}

function CandidateSummaryList() {
  const activeCandidates = useSelector(selectActiveCandidates);
  const archivedCandidates = useSelector(selectArchivedCandidates);

  const scheduledCandidates = activeCandidates.filter(
    (c) =>
      c.status === "Scheduled" &&
      c.scheduled_date !== null &&
      dayjs(c.scheduled_date).isoWeek() === dayjs(Date.now()).isoWeek()
  );

  const summaryList = [
    [activeCandidates.length, "Active Candidates"],
    [archivedCandidates.length, "Archived Candidates"],
    [scheduledCandidates.length, "Candidates scheduled this week"],
  ];

  const zip = (rows) => rows[0].map((_, c) => rows.map((row) => row[c]));
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {zip(summaryList).map((column, i) => (
        <Box key={i} sx={{ ...(i > 0 && { paddingLeft: 1 }) }}>
          {column.map((cell, j) => (
            <Typography
              key={j}
              sx={{ ...(i === 0 && { fontWeight: "fontWeightBold" }) }}
            >
              {cell}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
}

function CandidatesListView() {
  const candidates = useSelector(selectActiveCandidatesNormalized);
  const navigate = useNavigate();

  const scheduledCandidates = candidates
    .filter((c) => c.status === "Scheduled" && c.scheduled_date !== null)
    .map((c) => {
      return {
        title: `${c.name} - ${c.stage.name}`,
        date: c.scheduled_date,
        extendedProps: { pk: c.pk },
      };
    });

  return (
    <FullCalendar
      plugins={[listPlugin]}
      initialView="twoWeekGrid"
      headerToolbar={false}
      noEventsContent={
        <Typography>No candidates scheduled for this week</Typography>
      }
      eventClick={(e) =>
        navigate(CANDIDATE_DETAIL.generate(e.event.extendedProps.pk))
      }
      events={scheduledCandidates}
      views={{
        twoWeekGrid: {
          type: "list",
          duration: { days: 15 },
        },
      }}
    />
  );
}

function CandidatesMonthView() {
  const candidates = useSelector(selectActiveCandidates);
  const navigate = useNavigate();

  const scheduledCandidates = candidates
    .filter((c) => c.status === "Scheduled" && c.scheduled_date !== null)
    .map((c) => {
      return {
        title: c.name,
        date: c.scheduled_date,
        extendedProps: { pk: c.pk },
      };
    });

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      noEventsContent={
        <Typography>No candidates scheduled for this week</Typography>
      }
      events={scheduledCandidates}
      eventClick={(e) =>
        navigate(CANDIDATE_DETAIL.generate(e.event.extendedProps.pk))
      }
    />
  );
}

// Home.queries = [useGetUserQuery, useGetCandidatesQuery, useGetStagesQuery];

function _Home() {
  const theme = useTheme();
  const isSmallView = !useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <Card>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Agenda />
          </CardContent>
        </Card>
        <Box pt={2} />
        <Card>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ flexGrow: 0 }}>
              <Typography variant="h5">Candidate Summary</Typography>
              <Box pt={1} />
              <CandidateSummaryList />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <CandidatesChart />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {isSmallView ? (
        <React.Fragment>
          <Grid item xs={6} lg={4}>
            <Card>
              <CardContent>
                <CandidatesListView />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} lg={4}>
            <Card>
              <CardContent>
                <SchedulingList />
              </CardContent>
            </Card>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid item xs={6} lg={4}>
          <Card>
            <CardContent>
              <CandidatesListView />
            </CardContent>
          </Card>
          <Box pt={2} />
          <Card>
            <CardContent>
              <SchedulingList />
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <CandidatesMonthView />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function AgendaInput(props) {
  const [state, setState] = useState({
    value: "",
    typingTimeout: 0,
  });

  useEffect(() => {
    setState({ value: props.value, typingTimeout: 0 });
  }, [props.value]);

  const searchHandler = (event) => {
    if (state.typingTimeout) {
      clearTimeout(state.typingTimeout);
    }

    setState({
      value: event.target.value,
      typingTimeout: setTimeout(() => {
        const name = event.target.value;
        if (name.trim() !== "") {
          props.onChange(name);
        } else {
          props.onChange(null);
        }
      }, 500),
    });
  };

  return (
    <TextField
      multiline
      fullWidth
      minRows={8}
      variant="outlined"
      value={state.value}
      onChange={searchHandler}
    />
  );
}

function Agenda() {
  const user = useSelector(selectUserInfo);
  const [updateUser] = useUpdateUserMutation();

  const onChange = (agenda) => {
    // updateUser({ agenda: agenda});
  };

  return (
    <Box>
      <Typography variant="h5">Agenda</Typography>
      <Box pt={1} />
      <AgendaInput value={user.agenda} onChange={onChange} />
    </Box>
  );
}

export default function Home() {
  const { isLoading: isUserLoading } = useGetUserQuery();
  const { isLoading: isCandidatesLoading } = useGetCandidatesQuery();
  const { isLoading: isStagesLoading } = useGetStagesQuery();

  if (isUserLoading || isCandidatesLoading || isStagesLoading) {
    return <Loader />;
  } else {
    return <_Home />;
  }
}
