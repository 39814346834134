import React from "react";
import { useState } from "react";
import { Button, Checkbox, Menu, MenuItem } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

export default function CheckboxMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        variant="outlined"
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        color={props.color || "primary"}
      >
        {props.title}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.items.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => item.onClick(item.name)}
            sx={{
              justifyContent: "space-between",
              borderStyle: "solid",
              ...(item.backgroundColor && {
                borderWidth: "0 0 0 8px",
                borderColor: item.backgroundColor || "white",
              }),
            }}
          >
            {item.name}
            <Checkbox checked={item.checked} onChange={() => {}} />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
