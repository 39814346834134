import { generatePath, Link, Navigate, Route, Routes } from "react-router-dom";
import Candidates from "./pages/Candidates";
import Home from "./pages/Home";
import Archive from "./pages/Archive";
import Settings from "./pages/Settings";
import Preferences from "./pages/Preferences";
import CandidateDetail from "./pages/CandidateDetail";
import React from "react";
import Login from "./pages/Login";

// Taken from https://mui.com/guides/routing/#global-theme-link
export const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <Link ref={ref} to={href} {...other} />;
});

export const HOME = {
  route: "/",
  generate() {
    return generatePath(this.route);
  },
  element: Home,
};

export const CANDIDATES_LIST = {
  route: "/Candidates",
  generate() {
    return generatePath(this.route);
  },
  element: Candidates,
};

export const CANDIDATE_DETAIL = {
  route: "/Candidate/:pk",
  generate(pk) {
    return generatePath(this.route, { pk });
  },
  element: CandidateDetail,
};

export const ARCHIVE = {
  route: "/Archive",
  generate() {
    return generatePath(this.route);
  },
  element: Archive,
};

export const PREFERENCES = {
  route: "/Preferences",
  generate() {
    return generatePath(this.route);
  },
  element: Preferences,
};

export const SETTINGS = {
  route: "/Settings",
  generate() {
    return generatePath(this.route);
  },
  element: Settings,
};

export const LOGIN = {
  route: "/Login",
  generate() {
    return generatePath(this.route);
  },
  element: Login,
};

const PAGES = [
  HOME,
  CANDIDATES_LIST,
  ARCHIVE,
  CANDIDATE_DETAIL,
  PREFERENCES,
  SETTINGS,
  LOGIN,
];

export default function AppRouter() {
  return (
    <Routes>
      {PAGES.map((p, i) => {
        const Component = p.element;
        return <Route key={i} path={p.route} element={<Component />} />;
      })}
      <Route exact path="/" element={<Navigate to={HOME.generate()} />} />
    </Routes>
  );
}
