import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectStagesList } from "../state/StageSlice";
import React, { useState } from "react";
import { GithubPicker } from "react-color";
import { Add, Delete } from "@mui/icons-material";
import EditableText from "../components/atoms/EditableText";
import { selectAllCandidates } from "../state/CandidateSlice";
import {
  useAddStageMutation,
  useDeleteStageMutation,
  useGetCandidatesQuery,
  useGetStagesQuery,
  useUpdateStageMutation,
} from "../api/Service";
import Loader from "../components/atoms/Loader";

function Stages() {
  const candidates = useSelector(selectAllCandidates);
  const stages = useSelector(selectStagesList);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stage, setStage] = useState(null);
  const open = Boolean(anchorEl);
  const [updateStage] = useUpdateStageMutation();
  const [deleteStage] = useDeleteStageMutation();

  const validDeletes = candidates.reduce(
    (acc, c) => {
      acc[c.stage] = false;
      return acc;
    },
    stages.reduce((acc, i) => {
      acc[i.pk] = true;
      return acc;
    }, {})
  );

  const handleClick = (event, id) => {
    setStage(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setStage(null);
    setAnchorEl(null);
  };

  const handleChange = (color, e) => {
    updateStage({ pk: stage, color: color.hex.substring(1) });
    handleClose();
  };

  const handleDelete = (pk) => {
    deleteStage({ pk });
  };

  const handleNameChangeWrapper = (pk) => {
    return (name) => {
      updateStage({ pk, name });
    };
  };

  return (
    <Box>
      <Typography variant="h5">Candidate Stages</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
            <TableCell size="small" align="center" sx={{ fontWeight: "bold" }}>
              Color
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {stages.map((s, i) => (
            <TableRow key={i}>
              <TableCell>
                <EditableText
                  value={s.name}
                  onChange={handleNameChangeWrapper(s.pk)}
                />
              </TableCell>
              <TableCell size="small" align="center">
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: s.color,
                      borderRadius: "3px",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={(e) => handleClick(e, s.pk)}
                  />
                </Box>
              </TableCell>
              <TableCell padding="checkbox">
                <Tooltip
                  title={
                    !validDeletes[s.pk]
                      ? "You cannot delete a stage that is in use by some candidates"
                      : "Permanently delete this stage (there are no candidates that use it)"
                  }
                >
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(s.pk)}
                      disabled={!validDeletes[s.pk]}
                    >
                      <Delete color="gray" />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ backgroundColor: "inherit", marginTop: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <GithubPicker onChange={handleChange} />
      </Popover>
    </Box>
  );
}

function AddStage() {
  const [addStage] = useAddStageMutation();

  const handleClick = () => {
    addStage({
      name: "New Stage",
      color: Math.floor(Math.random() * 16777215).toString(16),
    });
  };

  return (
    <Button startIcon={<Add />} variant="outlined" onClick={handleClick}>
      Add stage
    </Button>
  );
}

// Preferences.queries = [useGetCandidatesQuery, useGetStagesQuery];

function _Preferences() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Card>
          <CardContent>
            <Stages />
            <Box pt={2} />
            <AddStage />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default function Preferences() {
  const { isLoading: isCandidatesLoading } = useGetCandidatesQuery();
  const { isLoading: isStagesLoading } = useGetStagesQuery();

  if (isCandidatesLoading || isStagesLoading) {
    return <Loader />;
  } else {
    return <_Preferences />;
  }
}
