import {
  BooleanInput,
  StageInput,
  StatusInput,
  TextInput,
} from "../atoms/CandidateInputs";
import React, { useState } from "react";
import { Button, Dialog } from "@mui/material";
import CandidateInputs from "../molecules/CandidateInputs";
import {
  useAddCandidateMutation,
  useUpdateCandidateMutation,
} from "../../api/Service";
import { AddCircleOutline } from "@mui/icons-material";

const CandidateFields = [
  {
    name: "Name",
    key: "name",
    component: TextInput,
  },
  {
    name: "Hiring Manager",
    key: "hiring_manager",
    component: TextInput,
  },
  {
    name: "In ATS?",
    key: "in_ats",
    component: BooleanInput,
  },
  {
    name: "Role",
    key: "role",
    component: TextInput,
  },
  { name: "ATS Link", key: "ats_link", component: TextInput, nullable: true },
  {
    name: "Stage",
    key: "stage",
    component: StageInput,
  },
  {
    name: "Status",
    key: "status_date",
    component: StatusInput,
    isError: (input) => {
      const isDateValid = (date) => {
        const dateField = date?.$d;
        return !(dateField instanceof Date && isNaN(dateField));
      };
      return (
        input.status === undefined ||
        !(input.scheduled_date === null || isDateValid(input.scheduled_date))
      );
    },
  },
  {
    name: "Notes",
    key: "notes",
    component: TextInput,
    nullable: true,
  },
];

const transformFields = (fields) => {
  let normalizedFields = fields.reduce((result, current) => {
    result[current.key] = current;
    return result;
  }, {});

  return CandidateFields.map((f) => ({ ...f, ...normalizedFields[f.key] }));
};

const transformResult = (result) => {
  result.status = result.status_date.status;
  result.scheduled_date = result.status_date.scheduled_date;
  delete result.status_date;
  return result;
};

export function CandidateModifier(props) {
  const [updateCandidate] = useUpdateCandidateMutation();

  const fields = transformFields([
    {
      key: "name",
      default: props.candidate.name,
    },
    {
      key: "hiring_manager",
      default: props.candidate.hiring_manager,
    },
    {
      key: "in_ats",
      default: props.candidate.in_ats,
    },
    {
      key: "role",
      default: props.candidate.role,
    },
    {
      key: "ats_link",
      default: props.candidate.ats_link,
    },
    {
      key: "status_date",
      default: {
        status: props.candidate.status,
        scheduled_date: props.candidate.scheduled_date,
      },
    },
    {
      key: "stage",
      default: props.candidate.stage?.pk,
    },
    {
      key: "notes",
      default: props.candidate.notes,
    },
  ]);

  const onConfirm = (state) => {
    let result = {};
    for (let key in transformResult(state)) {
      // TODO: Fix this hack, make it so that the candidate passed argument
      // of the component is passed in the normalized form (take a look at before
      // and after to get a better sense).
      if (key === "stage") {
        if (state.stage !== props.candidate.stage.pk) {
          result.stage = state.stage;
        }
      } else if (state[key] !== props.candidate[key]) {
        result[key] = state[key];
      }
    }

    if (Object.keys(result).length > 0) {
      result.pk = props.candidate.pk;
      updateCandidate(result)
        .unwrap()
        .then(() => {
          props.onClose();
        });
    } else {
      props.onClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <CandidateInputs
        pk={props.candidate.pk}
        fields={fields}
        onConfirm={onConfirm}
        onCancel={props.onClose}
        confirmText="Update Candidate"
      />
    </Dialog>
  );
}

export function CandidateAdder() {
  const [addCandidate] = useAddCandidateMutation();
  const fields = transformFields([
    {
      key: "name",
    },
    {
      key: "hiring_manager",
    },
    {
      key: "in_ats",
    },
    {
      key: "role",
    },
    {
      key: "ats_link",
    },
    {
      key: "status_date",
      default: {
        status: undefined,
        scheduled_date: undefined,
      },
    },
    {
      key: "stage",
    },
    {
      key: "notes",
    },
  ]);

  const [open, setOpen] = useState(false);

  const onAdd = (state) => {
    addCandidate(transformResult(state))
      .unwrap()
      .then(() => {
        setOpen(false);
      });
  };

  return (
    <React.Fragment>
      <Button
        id="candidate-adder-create-button"
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<AddCircleOutline />}
      >
        Add Candidate
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <CandidateInputs
          confirmButtonId="candidate-adder-confirm-button"
          fields={fields}
          onConfirm={onAdd}
          onCancel={() => setOpen(false)}
          confirmText="Add Candidate"
        />
      </Dialog>
    </React.Fragment>
  );
}
