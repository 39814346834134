import { useSelector } from "react-redux";
import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { selectStatusesList } from "../../state/StatusSlice";
import { selectStagesList } from "../../state/StageSlice";

function FilterSelector(props) {
  return (
    <TextField
      id={props.id}
      value={props.value}
      label={props.title}
      size="small"
      onChange={(e) => props.onChange(e.target.value)}
      select
      fullWidth
      error={props.error}
    >
      {props.values.map((s, i) => (
        <MenuItem key={i} value={s.pk}>
          {s.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

export function StatusSelector(props) {
  const statuses = useSelector(selectStatusesList);

  return (
    <FilterSelector
      id={props.id}
      title="Status"
      value={props.value}
      values={statuses.map((v) => {
        return { pk: v.name, name: v.name };
      })}
      onChange={props.onChange}
      error={props.error}
    />
  );
}

export function StageSelector(props) {
  const stages = useSelector(selectStagesList);

  return (
    <FilterSelector
      id={props.id}
      title="Stage"
      value={props.value}
      values={stages}
      onChange={props.onChange}
      error={props.error}
    />
  );
}
