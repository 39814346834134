import { Box, Card, CardContent, Typography } from "@mui/material";
import CandidateExporter from "../components/molecules/CandidateExporter";
import {
  useGetCandidatesQuery,
  useGetStagesQuery,
  useGetUserQuery,
} from "../api/Service";
import Loader from "../components/atoms/Loader";
import React from "react";
import { CandidateAdder } from "../components/organisms/CandidateEdit";
import ActiveCandidateTable from "../components/molecules/ActiveCandidateTable";

function _Candidates() {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">List of Active Candidates</Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CandidateExporter />
            <Box pr={2} />
            <CandidateAdder />
          </Box>
        </Box>
        <Box pt={2} />
        <ActiveCandidateTable />
      </CardContent>
    </Card>
  );
}

// Candidates.queries = [
//   useGetUserQuery,
//   useGetCandidatesQuery,
//   useGetStagesQuery,
// ];

export default function Candidates() {
  const { isLoading: isUserLoading } = useGetUserQuery();
  const { isLoading: isCandidatesLoading } = useGetCandidatesQuery();
  const { isLoading: isStagesLoading } = useGetStagesQuery();

  if (isUserLoading || isCandidatesLoading || isStagesLoading) {
    return <Loader />;
  } else {
    return <_Candidates />;
  }
}
