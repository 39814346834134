import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./UserSlice";
import candidatesReducer from "./CandidateSlice";
import statusReducer from "./StatusSlice";
import filterReducer from "./FilterSlice";
import stageReducer from "./StageSlice";
import { api } from "../api/Service";
import unauthorizedMiddleware from "../middleware/unauthorizedMiddleware";
import { errorHandlingMiddleware } from "../middleware/errorHandling";

const appReducer = combineReducers({
  user: userReducer,
  candidates: candidatesReducer,
  status: statusReducer,
  filter: filterReducer,
  stage: stageReducer,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/resetCredentials") {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      errorHandlingMiddleware,
      unauthorizedMiddleware,
      api.middleware,
    ]),
});
