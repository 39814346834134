import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { useLoginMutation } from "../api/Service";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCredentials } from "../state/UserSlice";
import { useNavigate } from "react-router-dom";
import { HOME } from "../AppRouter";

export default function Login() {
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submitLogin = async (e) => {
    e.preventDefault();
    const credentials = await login({ username, password });
    if (credentials.error === undefined) {
      dispatch(setCredentials(credentials.data));
      navigate(HOME.generate());
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#6d9ac4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "400px",
          height: "fit-content",
          display: "flex",
          justifyContent: "center",
          paddingY: 2,
        }}
      >
        <CardContent>
          <Typography variant="h6">Login</Typography>
          <Box pt={4} />
          <form onSubmit={submitLogin}>
            <TextField
              label="Username"
              variant="outlined"
              size="small"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Box pt={2} />
            <TextField
              label="Password"
              variant="outlined"
              size="small"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box pt={2} />
            <Button type="submit" variant="contained" fullWidth>
              Login
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}
