import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AccountCircle, Logout, Palette, Settings } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/UserSlice";
import Sidebar from "./Sidebar";
import { PREFERENCES, SETTINGS } from "../../AppRouter";
import { useLogoutMutation } from "../../api/Service";

function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const user = useSelector(selectUserInfo);
  const [logout] = useLogoutMutation();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Box>
      <IconButton id="header-user-open" edge="end" onClick={handleOpen}>
        <AccountCircle sx={{ color: "white" }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Typography id="header-user-info-name" variant="subtitle1">
            {user.username}
          </Typography>
          <Typography
            id="header-user-info-email"
            variant="subtitle2"
            color="textSecondary"
          >
            {user.email}
          </Typography>
        </Box>
        <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
        <MenuItem
          component={Link}
          to={PREFERENCES.generate()}
          onClick={handleClose}
        >
          <Palette />
          <Box pr={2} />
          <Typography>Preferences</Typography>
        </MenuItem>
        <MenuItem
          component={Link}
          to={SETTINGS.generate()}
          onClick={handleClose}
        >
          <Settings />
          <Box pr={2} />
          <Typography>Settings</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Logout />
          <Box pr={2} />
          <Typography>Log out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function Header() {
  return (
    <AppBar position="sticky">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Sidebar />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            My Candidate Tracker
          </Typography>
        </Box>
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
