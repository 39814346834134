import { isRejectedWithValue } from "@reduxjs/toolkit";
import { resetCredentials } from "../state/UserSlice";

const unauthorizedMiddleware =
  ({ dispatch, ...stuff }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(resetCredentials());
    }

    return next(action);
  };

export default unauthorizedMiddleware;
