import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialValues = {
  status: {},
  stage: {},
  search: null,
  hiring_manager: "",
  role: "",
  in_ats: null,
  scheduled_date: {
    from: null,
    to: null,
  },
};

// If searchResults is null, that means that we are not currently in a searching
// state, so no filter is applied.
const initialState = {
  archived: JSON.parse(JSON.stringify(initialValues)),
  active: JSON.parse(JSON.stringify(initialValues)),
};

const reducerFunctions = {
  addStateFilter: (set) => (s, action) => {
    let state = s[set];
    state.status[action.payload] = true;
  },
  toggleStateFilter: (set) => (s, action) => {
    let state = s[set];
    if (action.payload in state.status) {
      delete state.status[action.payload];
    } else {
      state.status[action.payload] = true;
    }
  },
  addStageFilter: (set) => (s, action) => {
    let state = s[set];
    state.stage[action.payload] = true;
  },
  toggleStageFilter: (set) => (s, action) => {
    let state = s[set];
    if (action.payload in state.stage) {
      delete state.stage[action.payload];
    } else {
      state.stage[action.payload] = true;
    }
  },
  resetSearchFilter: (set) => (s) => {
    s[set] = JSON.parse(JSON.stringify(initialValues));
  },
  setSearchFilter: (set) => (s, action) => {
    let state = s[set];
    state.search = action.payload;
  },
  setHiringManager: (set) => (s, action) => {
    let state = s[set];
    state.hiring_manager = action.payload;
  },
  setRole: (set) => (s, action) => {
    let state = s[set];
    state.role = action.payload;
  },
  setInAts: (set) => (s, action) => {
    let state = s[set];
    if (action.payload === "") {
      state.in_ats = null;
    }
    if (action.payload === "yes") {
      state.in_ats = true;
    }
    if (action.payload === "no") {
      state.in_ats = false;
    }
  },
  setScheduledDate: (set) => (s, action) => {
    let state = s[set];
    if ("from" in action.payload) {
      state.scheduled_date.from = action.payload.from;
    }
    if ("to" in action.payload) {
      state.scheduled_date.to = action.payload.to;
    }
  },
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addStateFilter: reducerFunctions.addStateFilter("active"),
    toggleStateFilter: reducerFunctions.toggleStateFilter("active"),
    addStageFilter: reducerFunctions.addStageFilter("active"),
    toggleStageFilter: reducerFunctions.toggleStageFilter("active"),
    resetSearchFilter: reducerFunctions.resetSearchFilter("active"),
    setSearchFilter: reducerFunctions.setSearchFilter("active"),
    setHiringManager: reducerFunctions.setHiringManager("active"),
    setRole: reducerFunctions.setRole("active"),
    setInAts: reducerFunctions.setInAts("active"),
    setScheduledDate: reducerFunctions.setScheduledDate("active"),

    archivedAddStateFilter: reducerFunctions.addStateFilter("archived"),
    archivedToggleStateFilter: reducerFunctions.toggleStateFilter("archived"),
    archivedAddStageFilter: reducerFunctions.addStageFilter("archived"),
    archivedToggleStageFilter: reducerFunctions.toggleStageFilter("archived"),
    archivedResetSearchFilter: reducerFunctions.resetSearchFilter("archived"),
    archivedSetSearchFilter: reducerFunctions.setSearchFilter("archived"),
    archivedSetHiringManager: reducerFunctions.setHiringManager("archived"),
    archivedSetRole: reducerFunctions.setRole("archived"),
    archivedSetInAts: reducerFunctions.setInAts("archived"),
    archivedSetScheduledDate: reducerFunctions.setScheduledDate("archived"),
  },
});

export const selectFilters = (state) => state.filter.active;

export const selectHiringManager = createSelector(
  [selectFilters],
  (f) => f.hiring_manager || ""
);

export const selectSearchName = createSelector(
  [selectFilters],
  (f) => f.search
);

export const selectRole = createSelector([selectFilters], (f) => f.role || "");

export const selectInAts = createSelector([selectFilters], (f) => {
  if (f.in_ats === null) {
    return "";
  }
  if (f.in_ats === true) {
    return "yes";
  }
  if (f.in_ats === false) {
    return "no";
  }
});

export const selectScheduledDate = createSelector(
  [selectFilters],
  (f) => f.scheduled_date
);

export const selectArchivedFilters = (state) => state.filter.archived;

export const selectArchivedHiringManager = createSelector(
  [selectArchivedFilters],
  (f) => f.hiring_manager || ""
);

export const selectArchivedSearchName = createSelector(
  [selectArchivedFilters],
  (f) => f.search
);

export const selectArchivedRole = createSelector(
  [selectArchivedFilters],
  (f) => f.role || ""
);

export const selectArchivedInAts = createSelector(
  [selectArchivedFilters],
  (f) => {
    if (f.in_ats === null) {
      return "";
    }
    if (f.in_ats === true) {
      return "yes";
    }
    if (f.in_ats === false) {
      return "no";
    }
  }
);

export const selectArchivedScheduledDate = createSelector(
  [selectArchivedFilters],
  (f) => f.scheduled_date
);

export const {
  addStateFilter,
  toggleStateFilter,
  addStageFilter,
  toggleStageFilter,
  resetSearchFilter,
  setSearchFilter,
  setHiringManager,
  setInAts,
  setRole,
  setScheduledDate,

  archivedAddStateFilter,
  archivedToggleStateFilter,
  archivedAddStageFilter,
  archivedToggleStageFilter,
  archivedResetSearchFilter,
  archivedSetSearchFilter,
  archivedSetHiringManager,
  archivedSetInAts,
  archivedSetRole,
  archivedSetScheduledDate,
} = filterSlice.actions;
export default filterSlice.reducer;
