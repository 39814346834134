import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

export default function TextSearch(props) {
  const [state, setState] = useState({
    value: "",
    typingTimeout: 0,
  });

  useEffect(() => {
    setState({ value: props.value, typingTimeout: 0 });
  }, [props.value]);

  const searchHandler = (event) => {
    if (state.typingTimeout) {
      clearTimeout(state.typingTimeout);
    }

    setState({
      value: event.target.value,
      typingTimeout: setTimeout(() => {
        const name = event.target.value;
        if (name.trim() !== "") {
          props.onChange(name);
        } else {
          props.onChange(null);
        }
      }, 500),
    });
  };

  return (
    <TextField
      size="small"
      variant="outlined"
      label={props.label}
      value={state.value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      onChange={searchHandler}
    />
  );
}
