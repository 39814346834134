import { createSelector, createSlice } from "@reduxjs/toolkit";
import Fuse from "fuse.js";
import {
  selectArchivedFilters,
  selectArchivedSearchName,
  selectFilters,
  selectSearchName,
} from "./FilterSlice";
import { selectStages } from "./StageSlice";
import { api } from "../api/Service";
const initialState = {};

const candidatesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {},
});

export const selectCandidatesResult = api.endpoints.getCandidates.select();

export const selectAllCandidates = createSelector(
  selectCandidatesResult,
  (result) => result?.data ?? []
);

export const selectArchivedCandidates = createSelector(
  [selectAllCandidates],
  (candidates) => candidates.filter((c) => c.is_archived)
);

export const selectActiveCandidates = createSelector(
  [selectAllCandidates],
  (candidates) => candidates.filter((c) => !c.is_archived)
);

export const selectActiveCandidatesNormalized = createSelector(
  [selectActiveCandidates, selectStages],
  (candidates, stages) =>
    candidates.map((c) => {
      let candidate = { ...c };
      candidate.stage = stages[candidate.stage];
      return candidate;
    })
);
export const selectArchivedCandidatesNormalized = createSelector(
  [selectArchivedCandidates, selectStages],
  (candidates, stages) =>
    candidates.map((c) => {
      let candidate = { ...c };
      candidate.stage = stages[candidate.stage];
      return candidate;
    })
);

// TODO: Clean these up
export const selectCandidatesNormalizedMap = createSelector(
  [selectAllCandidates, selectStages],
  (candidates, stages) => {
    return Object.fromEntries(
      candidates.map((c) => {
        let map = { ...c };
        map.stage = stages[c.stage];
        return [c.pk, map];
      })
    );
  }
);

export const selectCandidatesNormalized = createSelector(
  [selectCandidatesNormalizedMap],
  (candidates) => Object.values(candidates)
);

export const selectCandidateByName = createSelector(
  [selectCandidatesNormalized],
  (c) => {
    return Object.values(c).reduce((acc, v) => {
      acc[v.name] = v;
      return acc;
    }, {});
  }
);

export const selectArchivedCandidateByName = createSelector(
  [selectArchivedCandidatesNormalized],
  (c) => {
    return Object.values(c).reduce((acc, v) => {
      acc[v.name] = v;
      return acc;
    }, {});
  }
);

export const selectCandidateHiringManagers = createSelector(
  [selectActiveCandidates],
  (candidates) => Array.from(new Set(candidates.map((c) => c.hiring_manager)))
);

export const selectArchivedCandidateHiringManagers = createSelector(
  [selectArchivedCandidates],
  (candidates) => Array.from(new Set(candidates.map((c) => c.hiring_manager)))
);

export const selectCandidateRoles = createSelector(
  [selectActiveCandidates],
  (candidates) => Array.from(new Set(candidates.map((c) => c.role)))
);

export const selectArchivedCandidateRoles = createSelector(
  [selectArchivedCandidates],
  (candidates) => Array.from(new Set(candidates.map((c) => c.role)))
);

export const selectUnarchivedCandidates = createSelector(
  [selectCandidatesNormalized],
  (candidates) => candidates.filter((c) => !c.is_archived)
);

const createFuseFilter = (candidateSelector) => {
  return createSelector([candidateSelector], (c) => {
    const fuse = new Fuse([], {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      ignoreLocation: true,
      ignoreFieldNorm: true,
    });

    for (const candidate of c) {
      fuse.add(candidate.name);
    }

    return fuse;
  });
};

const selectCandidateFuseFilter = createFuseFilter(selectUnarchivedCandidates);
const selectArchivedCandidateFuseFilter = createFuseFilter(
  selectArchivedCandidates
);

export const selectCandidateNameSearchIds = createSelector(
  [selectCandidateFuseFilter, selectSearchName],
  (fuse, name) => {
    if (name === null) {
      return null;
    }
    return fuse.search(name);
  }
);

export const selectArchivedCandidateNameSearchIds = createSelector(
  [selectArchivedCandidateFuseFilter, selectArchivedSearchName],
  (fuse, name) => {
    if (name === null) {
      return null;
    }
    return fuse.search(name);
  }
);

const createFilteredCandidatesSelector = (selectors) => {
  return createSelector(
    selectors,
    (candidates, candidatesByName, filters, searchNames) => {
      candidates =
        searchNames === null
          ? candidates
          : searchNames.map((v) => candidatesByName[v.item]);

      candidates =
        Object.keys(filters.status).length === 0
          ? candidates
          : candidates.filter(
              (candidate) => candidate.status in filters.status
            );

      candidates =
        Object.keys(filters.stage).length === 0
          ? candidates
          : candidates.filter(
              (candidate) => candidate.stage.name in filters.stage
            );

      candidates =
        filters.hiring_manager === ""
          ? candidates
          : candidates.filter(
              (candidate) =>
                candidate.hiring_manager.toLowerCase() ===
                filters.hiring_manager.toLowerCase()
            );

      candidates =
        filters.role === ""
          ? candidates
          : candidates.filter(
              (candidate) =>
                candidate.role.toLowerCase() === filters.role.toLowerCase()
            );

      candidates =
        filters.in_ats === null
          ? candidates
          : candidates.filter(
              (candidate) => candidate.in_ats === filters.in_ats
            );

      if (
        filters.scheduled_date.from !== null ||
        filters.scheduled_date.to !== null
      ) {
        candidates = candidates.filter(
          (candidate) => candidate.scheduled_date !== null
        );

        if (filters.scheduled_date.from !== null) {
          candidates = candidates.filter(
            (candidate) =>
              new Date(filters.scheduled_date.from) <=
              new Date(candidate.scheduled_date)
          );
        }
        if (filters.scheduled_date.to !== null) {
          candidates = candidates.filter(
            (candidate) =>
              new Date(candidate.scheduled_date) <=
              new Date(filters.scheduled_date.to)
          );
        }
      }

      return candidates;
    }
  );
};

export const selectFilteredCandidates = createFilteredCandidatesSelector([
  selectUnarchivedCandidates,
  selectCandidateByName,
  selectFilters,
  selectCandidateNameSearchIds,
]);

export const selectFilteredArchivedCandidates =
  createFilteredCandidatesSelector([
    selectArchivedCandidatesNormalized,
    selectArchivedCandidateByName,
    selectArchivedFilters,
    selectArchivedCandidateNameSearchIds,
  ]);

// export const {} = candidatesSlice.actions;
export default candidatesSlice.reducer;
