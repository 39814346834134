import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { StageSelector, StatusSelector } from "./FilterSelector";
import React from "react";
import { DateTimePicker } from "@mui/lab";

export function TextInput(props) {
  return (
    <FormControl variant="standard" error={props.error} fullWidth>
      <InputLabel>{props.name}</InputLabel>
      <Input
        id={props.id}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <FormHelperText>
        {props.error && "Please input a valid value."}
      </FormHelperText>
    </FormControl>
  );
}

export function StatusInput(props) {
  const updateStatus = (v) => {
    props.onChange({ ...props.value, status: v });
  };

  const updateDate = (d) => {
    props.onChange({ ...props.value, scheduled_date: d });
  };

  return (
    <Box>
      <FormControl variant="standard" fullWidth error={props.error}>
        <StatusSelector
          id={props.id}
          value={props.value.status ?? ""}
          onChange={updateStatus}
          error={props.error}
        />
        <FormHelperText>
          {props.error && "Please input a valid value."}
        </FormHelperText>
      </FormControl>
      <Box pt={1} />
      {props.value.status === "Scheduled" && (
        <DateTimePicker
          label={"Scheduled for"}
          value={props.value.scheduled_date}
          onChange={updateDate}
          renderInput={(params) => <TextField {...params} />}
          clearable
        />
      )}
    </Box>
  );
}

export function StageInput(props) {
  return (
    <FormControl variant="standard" fullWidth error={props.error}>
      <StageSelector
        id={props.id}
        value={props.value}
        onChange={(e) => props.onChange(e)}
        error={props.error}
      />
      <FormHelperText>
        {props.error && "Please input a valid value."}
      </FormHelperText>
    </FormControl>
  );
}

export function BooleanInput(props) {
  const onChange = (e) => props.onChange(e.target.value === "true");
  const toValue = (v) => {
    if (v === "") {
      return "";
    }
    return v ? "true" : "false";
  };

  return (
    <FormControl id={props.id} variant="standard" fullWidth error={props.error}>
      <InputLabel>{props.name}</InputLabel>
      <Select
        value={toValue(props.value)}
        label={props.name}
        onChange={onChange}
      >
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </Select>
      <FormHelperText>
        {props.error && "Please input a valid value."}
      </FormHelperText>
    </FormControl>
  );
}
