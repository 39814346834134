import { Box, Chip } from "@mui/material";
import React from "react";

function FilterChips(props) {
  return (
    <React.Fragment>
      {Object.keys(props.filters).map((filter) => (
        <React.Fragment key={filter}>
          <Chip
            label={filter}
            variant="outlined"
            onClick={() => props.onClick(filter)}
            onDelete={() => props.onClick(filter)}
            color={props.color}
          />
          <Box sx={{ paddingRight: 2 }} />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export function StatusChips(props) {
  return (
    <FilterChips
      filters={props.filters.status}
      onClick={props.onClick}
      color="secondary"
    />
  );
}

export function StageChips(props) {
  return (
    <FilterChips
      filters={props.filters.stage}
      onClick={props.onClick}
      color="tertiary"
    />
  );
}
